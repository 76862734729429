import React, { Component } from 'react';
import User from './User'
import { DisplayBusReservation, DisplayFinancialRecord } from '../config/config'

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = { loading: true, progress: { "GinoStep1Count": 0, "GinoStep2Count": 0 } };
    }

    componentDidMount() {
        this.populateProgressData();
    }

    static renderNyukin() {
        var nyukin;
        if (DisplayFinancialRecord > 0) {
            nyukin = (                
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" area-expanded="true" aria-controls="collapseOne">
                                入金情報
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" area-aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <ul class="list-group">
                                    <li class="list-group-item">ご入金：\200,000-</li>
                                    <li class="list-group-item">売　上：\150,000-</li>
                                    <li class="list-group-item">残　高：\ 50,000-</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>);
        }
        return nyukin;
    }

    static renderKyoshu(displayNo, steplist, reservelist) {
        if (steplist && steplist.includes(displayNo)) {
            return (<button className="btn btn-primary btn-sm">{displayNo}</button>);
        }
        if (reservelist && reservelist.includes(displayNo)) {
            return (<button className="btn btn-warning btn-sm">{displayNo}</button>);
        }
        else {
            return (<button className="btn btn-light btn-sm">{displayNo}</button>);
        }
    }

    static renderKyoshuSet(stepcount, steplist, reservelist) {
        const rows = [];
        for (var i = 0; i < stepcount; i++) {
            rows.push(Home.renderKyoshu(i + 1, steplist, reservelist));
        }
        return rows;
    }

    static renderBus() {
        var userNumber = User.getNumber();
        if (DisplayBusReservation && userNumber === '2200475') {
            return (
                <div class="btn-group">
                    <h4>バス予約</h4>
                    <a href="/bus">バスルート情報へ</a>
                    <p />
                </div>);
        }
    }

    static renderProgress(progress) {
        return (
            <div>
                <h6>{progress.name}さん、ようこそ！(番号:{User.getNumber()})</h6>
                {Home.renderBus()}
                <h4>技能教習進捗</h4>
                <h6>１段階</h6>
                <div class="btn-group">
                    {Home.renderKyoshuSet(progress.ginoStep1Count, progress.ginoStep1List, progress.ginoStep1ReserveList)}
                </div>
                <p />
                <h6>２段階</h6>
                <div class="btn-group">
                    {Home.renderKyoshuSet(progress.ginoStep2Count, progress.ginoStep2List, progress.ginoStep2ReserveList)}
                </div>

                <p />
                <h4>学科教習進捗</h4>
                <h6>１段階</h6>
                <div class="btn-group">
                    {Home.renderKyoshu(1, progress.gakkaStep1List)}
                    {Home.renderKyoshu(2, progress.gakkaStep1List)}
                    {Home.renderKyoshu(3, progress.gakkaStep1List)}
                    {Home.renderKyoshu(4, progress.gakkaStep1List)}
                    {Home.renderKyoshu(5, progress.gakkaStep1List)}
                    {Home.renderKyoshu(6, progress.gakkaStep1List)}
                    {Home.renderKyoshu(7, progress.gakkaStep1List)}
                    {Home.renderKyoshu(8, progress.gakkaStep1List)}
                    {Home.renderKyoshu(9, progress.gakkaStep1List)}
                    {Home.renderKyoshu(10, progress.gakkaStep1List)}
                </div>
                <p />
                <h6>２段階</h6>
                <div class="btn-group">
                    {Home.renderKyoshu(1, progress.gakkaStep2List)}
                    {Home.renderKyoshu(2, progress.gakkaStep2List)}
                    {Home.renderKyoshu(3, progress.gakkaStep2List)}
                    {Home.renderKyoshu(4, progress.gakkaStep2List)}
                    {Home.renderKyoshu(5, progress.gakkaStep2List)}
                    {Home.renderKyoshu(6, progress.gakkaStep2List)}
                    {Home.renderKyoshu(7, progress.gakkaStep2List)}
                    {Home.renderKyoshu(8, progress.gakkaStep2List)}
                    {Home.renderKyoshu(9, progress.gakkaStep2List)}
                    {Home.renderKyoshu(10, progress.gakkaStep2List)}
                    {Home.renderKyoshu(11, progress.gakkaStep2List)}
                    {Home.renderKyoshu(12, progress.gakkaStep2List)}
                    {Home.renderKyoshu(13, progress.gakkaStep2List)}
                    {Home.renderKyoshu(14, progress.gakkaStep2List)}
                    {Home.renderKyoshu(15, progress.gakkaStep2List)}
                    {Home.renderKyoshu(16, progress.gakkaStep2List)}
                </div>
                <p />
                <button className="btn btn-primary btn-sm">済は青色</button>&nbsp;
                <button className="btn btn-warning btn-sm">予約は黄色</button>
            </div>);
    }

    render() {
        let progressContents = this.state.loading
            ? <p><em>読み込み中...</em></p>
            : Home.renderProgress(this.state.progress);

        return progressContents;
    }

    async populateProgressData() {
        const userprogressresponse = await fetch('/user/getuserprogress?userNumber=' + User.getNumber());
        const userprogress = await userprogressresponse.json();
        this.setState({ loading: false, progress: userprogress });
    }
}
